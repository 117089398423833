import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, FieldGroup, FieldRow, FieldSet } from "react-corner-ds";
import { Trans, useTranslation } from "react-i18next";
import { WebAccount } from "../../../models/response/response.model";
import style from "./CtWidgetDemoSuccess.module.css";
import copyLogo from "../../../assets/img/demo/copy.svg";
import infoLogo from "../../../assets/img/demo/info.svg";

interface ICtWidgetDemoSuccess {
  webAccount?: WebAccount;
  contactMail?: string;
  stepForm?: string;
}

const CtWidgetDemoSuccess: React.FC<ICtWidgetDemoSuccess> = ({
  webAccount,
  contactMail,
  stepForm,
}) => {
  const { t } = useTranslation();
  const [isCredential, setIsCredential] = useState<boolean>(false);
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  const hostName = window.location.host;
  const loginPlatform =
    hostName.includes("cornertrader.ch") || hostName.includes("swisspost")
      ? `https://demo.platform.cornertrader.ch`
      : `https://demo.platform.cornertrader.com`;

  useEffect(() => {
    setIsCredential(
      webAccount &&
        webAccount?.ExtUserID !== "" &&
        webAccount?.PasswordClear !== ""
        ? true
        : false
    );
  }, [webAccount]);

  const scrollDataWidget = document.querySelector(
    '[data-widget="ct-widget-demo"]'
  )?.scrollTop;

  window.scrollTo({ top: scrollDataWidget, behavior: "smooth" });

  useEffect(() => {
    window.buildSuccessFormPageName("demo");
  }, []);

  const showTitle = useMemo(() => {
    switch (stepForm) {
      case "CountryNotAllowed":
        return <h2>{t`success.demo.pageTitleCountryNotAllowed`}</h2>;

      case "CountryAlmostAllowed":
        return <h2>{t`success.demo.pageTitleCountryAlmostAllowed`}</h2>;

      default:
        return isCredential ? (
          <h2>{t`success.demo.pageTitleCredential`}</h2>
        ) : (
          <h2>{t`success.demo.pageTitleFormSubmitted`}</h2>
        );
    }
  }, [isCredential, stepForm, t]);

  const showSubtitle = useMemo(() => {
    switch (stepForm) {
      case "CountryNotAllowed":
        return <h4>{t`success.demo.pageSubtitleCountryNotAllowed`}</h4>;

      case "CountryAlmostAllowed":
        return (
          <h4>
            <Trans i18nKey={t`success.demo.pageSubtitleCountryAlmostAllowed`} />
          </h4>
        );

      default:
        return isCredential ? (
          <span>
            <Trans
              i18nKey={t`success.demo.pageSubtitleCredential`}
              components={{
                Value: (
                  <span className={style.demoSuccessValue}>{contactMail}</span>
                ),
              }}
              values={{ contactMail: contactMail }}
            />
          </span>
        ) : (
          <h4>{t`success.demo.pageSubtitleFormSubmitted`}</h4>
        );
    }
  }, [contactMail, isCredential, stepForm, t]);

  const copyToClipboard = (text?: string) => {
    const textToCopy = text ?? "";
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsShowTooltip(true);
      setTimeout(() => setIsShowTooltip(false), 750);
    });
  };

  const demoLoginLink = useCallback(
    () => window.open(loginPlatform, "_blank"),
    [loginPlatform]
  );

  const widgetWithCredential = useMemo(() => {
    return (
      <div className={style.demoSuccess}>
        <FieldSet>
          <FieldRow customCssClass={style.demoSuccessCustomRow}>
            <FieldGroup customCssClass={style.demoSuccessTitle}>
              {showTitle}
            </FieldGroup>
            <FieldGroup>
              <h4>{t`success.demo.credential`}</h4>
            </FieldGroup>
            <FieldGroup customCssClass={style.demoSuccessSubTitle}>
              <img src={infoLogo} alt="info logo" width={22} height={22} />
              {showSubtitle}
            </FieldGroup>
          </FieldRow>

          <FieldRow customCssClass={style.demoSuccessCustomRow}>
            <FieldGroup customCssClass={style.demoSuccessCredentials}>
              <h4 className={style.demoSuccessLabel}>
                {t`success.demo.userId`}
              </h4>
              <h4
                className={style.demoSuccessClipboardBox}
                onClick={() => copyToClipboard(webAccount?.ExtUserID)}
              >
                <span className={style.demoSuccessValue}>
                  {webAccount?.ExtUserID}
                </span>
                <img src={copyLogo} alt="copy icon" />
              </h4>
            </FieldGroup>
            <FieldGroup customCssClass={style.demoSuccessCredentials}>
              <h4 className={style.demoSuccessLabel}>
                {t`success.demo.password`}
              </h4>
              <h4
                className={style.demoSuccessClipboardBox}
                onClick={() => copyToClipboard(webAccount?.PasswordClear)}
              >
                <span className={style.demoSuccessValue}>
                  {webAccount?.PasswordClear}
                </span>
                <img src={copyLogo} alt="copy icon" />
              </h4>
            </FieldGroup>
          </FieldRow>

          {isShowTooltip && (
            <div className={style.demoSuccessTooltip}>
              <span>
                <i className={`${style.customIcon} cticonapp-ok`}></i>
                {t`success.demo.tooltip`}
              </span>
            </div>
          )}

          <FieldRow>
            <FieldGroup customCssClass={style.customButton}>
              <button
                type="button"
                onClick={() => demoLoginLink()}
                className={`${style.demoSuccessButton} trkDemo`}
              >
                <span>{t`success.demo.demoLoginButton`}</span>
              </button>
            </FieldGroup>
          </FieldRow>
        </FieldSet>
      </div>
    );
  }, [
    demoLoginLink,
    showSubtitle,
    showTitle,
    isShowTooltip,
    t,
    webAccount?.ExtUserID,
    webAccount?.PasswordClear,
  ]);

  const widgetWithoutCredential = useMemo(() => {
    return (
      <div className={style.demoSuccess}>
        <FieldSet>
          <FieldRow>
            <FieldGroup customCssClass={style.demoSuccessTitle}>
              {stepForm === "FormSubmitted" ? (
                <h2>
                  <i className="cticonapp-ok-2"></i>
                </h2>
              ) : (
                <></>
              )}
              {showTitle}
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup customCssClass={style.demoSuccessSubTitle}>
              {showSubtitle}
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup customCssClass={style.customButton}>
              <Button
                type="button"
                customCssClass={`${style.closeButton} close-modal`}
                text={t`success.demo.closeButton`}
              />
            </FieldGroup>
          </FieldRow>
        </FieldSet>
      </div>
    );
  }, [showSubtitle, showTitle, stepForm, t]);

  return isCredential ? widgetWithCredential : widgetWithoutCredential;
};

export default CtWidgetDemoSuccess;
